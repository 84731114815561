import _objectSpread from "D:/Source/Repos/WallinServicesGui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import tableHeightMixin from "@/mixins/tableHeightMixin";
import NewsService from "@/services/NewsService";
export default {
  name: "News",
  mixins: [tableHeightMixin],
  data: function data() {
    return {
      loadingDownload: {}
    };
  },
  mounted: function mounted() {//
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    newsApiStatus: function newsApiStatus(state) {
      return state.newsApiStatus;
    }
  })), {}, {
    headers: function headers() {
      return [{
        text: "Name",
        value: "name"
      }, {
        text: "Source Id",
        value: "id"
      }, {
        text: "Last update",
        value: "lastUpdate"
      }, {
        text: "Status",
        value: "status"
      }, {
        text: "Articles",
        value: "articles"
      }, {
        text: "Actions",
        value: "action"
      }];
    }
  }),
  methods: {
    startDownload: function startDownload(item) {
      var _this = this;

      var feed = this.newsApiStatus.find(function (f) {
        return f.id === item.id;
      });

      if (feed) {
        this.$set(feed, "loadingDownload", true);
        NewsService.startDownloadNewsApi(feed.id).then(function (result) {
          _this.$set(feed, "resultChecker", setTimeout(function () {
            _this.checkDownloadResult(feed.id, result.data.statusQueryGetUri);
          }));
        });
      }
    },
    checkDownloadResult: function checkDownloadResult(id, url) {
      var _this2 = this;

      this.$axios.get(url).then(function (result) {
        var feed = _this2.newsApiStatus.find(function (f) {
          return f.id === id;
        });

        if (feed) {
          switch (result.data.runtimeStatus) {
            case "Completed":
              _this2.$set(feed, "loadingDownload", false);

              _this2.$set(feed, "resultChecker", null);

              _this2.$notify({
                type: "success",
                text: "Download started correctly!"
              });

              NewsService.getNewsApiFeed(id).then(function (feedResult) {
                var index = _this2.newsApiStatus.findIndex(function (f) {
                  return f.id === id;
                });

                if (index >= 0) {
                  _this2.newsApiStatus.splice(index, 1, feedResult.data);

                  switch (feedResult.data.status) {
                    case 1:
                      _this2.$notify({
                        type: "success",
                        text: "Feed ".concat(feedResult.data.name, " downloaded correctly!")
                      });

                      break;

                    case 3:
                      _this2.$notify({
                        type: "error",
                        text: "Feed ".concat(feedResult.data.name, " returned and error during download!")
                      });

                      break;
                  }
                }
              });
              break;

            case "Pending":
            case "Running":
              _this2.$set(feed, "resultChecker", setTimeout(function () {
                _this2.checkDownloadResult(id, url);
              }));

              break;

            case "Canceled":
            case "Failed":
            case "Terminated":
            case "Unknown":
              _this2.$set(feed, "loadingDownload", false);

              _this2.$notify({
                type: "error",
                text: "An error occurred, result from server was: ".concat(result.data.runtimeStatus, "\nTask Id: ").concat(result.data.id)
              });

              break;
          }
        }
      });
    }
  }
};