var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.newsApiStatus,"items-per-page":20,"height":_vm.tableHeight,"fixed-header":"","show-expand":"","dense":""},scopedSlots:_vm._u([{key:"item.lastUpdate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.lastUpdate)))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("feedStatus")(item.status)))]}},{key:"item.articles",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.articles ? item.articles.length : 0))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.loadingDownload)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"size":"24","width":"3","color":"grey","indeterminate":""}}):_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.startDownload(item)}}},[_vm._v("mdi-download")]),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":'/newsapi/' + item.id + '/articles'}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('p',{staticClass:"mt-2"},[_vm._v("Url: "+_vm._s(item.url))]),_c('p',[_vm._v(_vm._s(item.description))]),_c('p',[_vm._v("Category: "+_vm._s(item.category))]),(item.messageStatus)?_c('p',[_vm._v("Error: "+_vm._s(item.messageStatus))]):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }